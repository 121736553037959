import React, { useEffect, useMemo, useState } from 'react';
import { navigate } from 'gatsby';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/gatsby';
import { IoHelpCircleOutline } from 'react-icons/io5';
import SEO from '../meta/SEO';
import { DashboardContext } from '../../context/DashboardContext';
import { DashboardSidebar } from '../dashboard/DashboardSidebar';
import { useAuth } from '../../hooks/useAuth';
import { getBusinessData } from '../../gql/queries/getBusinessData';
import { useWebsiteContext } from '../../context';
import { FeedbackModal } from '../modals/FeedbackModal';

interface DashboardLayoutProps {
  children?: React.ReactNode;
  entryPath?: string;
}

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  entryPath,
}) => {
  const [activeLocation, setActiveLocation] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const { checkAuthStatus } = useAuth();
  const { setModalContent } = useWebsiteContext();

  useEffect(() => {
    const getUser = async () => {
      try {
        const { user } = await checkAuthStatus();

        if (!user) {
          navigate('/account/login/');
        } else {
          setUserId(user?.userId || '');
        }
      } catch (error) {
        console.error('Error getting user: ', error);
        Sentry.captureException(error, {
          tags: {
            page: 'dashboard',
            query: 'getUserProfile',
          },
        });
        navigate('/account/login/');
      }
    };

    getUser();
  }, []);

  const [getData, { data: businessData, loading: businessDataLoading }] =
    useLazyQuery(getBusinessData, {
      onError: error => {
        console.error('Error getting business data: ', error);
        Sentry.captureException(error, {
          tags: {
            page: 'dashboard',
            query: 'getBusinessData',
          },
        });
      },
    });

  useEffect(() => {
    if (userId) {
      getData({
        variables: {
          userId,
        },
      });
    }
  }, [userId]);

  useEffect(() => {
    if (
      entryPath === '/dashboard' &&
      businessData?.business &&
      !activeLocation
    ) {
      if (businessData.business.colivings.length > 0) {
        navigate(`/dashboard/${businessData.business.colivings[0].id}`);
      } else if (businessData.business.coworkings.length > 0) {
        navigate(`/dashboard/${businessData.business.coworkings[0].id}`);
      }
    }
  }, [businessData]);

  const dashboardState = useMemo(
    () => ({
      colivings: businessData?.business?.colivings || [],
      coworkings: businessData?.business?.coworkings || [],
      businessDataLoading,
      activeLocation,
      setActiveLocation,
      user: businessData?.user || null,
    }),
    [businessData, businessDataLoading, activeLocation],
  );

  return (
    <DashboardContext.Provider value={dashboardState}>
      {userId && (
        <main className="flex min-h-screen dashboard">
          <SEO title="Dashboard" noIndex />

          <DashboardSidebar />

          <div className="relative flex-1 pt-4 pb-20 mt-16 md:py-10 md:ml-72 md:mt-0 bg-gray-50">
            <div className="flex flex-col max-w-2xl gap-6 mx-4 lg:mx-auto">
              {children}
            </div>
          </div>

          <button
            type="button"
            aria-label="Get Help or Send Feedback"
            className="fixed flex items-center justify-center w-12 h-12 transition-all bg-blue-500 rounded-full shadow-md text-blue-50 hover:text-blue-100 hover:shadow bottom-4 right-4 hover:bg-blue-600"
            onClick={() =>
              setModalContent({
                title: 'Get Help or Send Feedback',
                content: <FeedbackModal user={businessData?.user || null} />,
              })
            }
          >
            <IoHelpCircleOutline className="w-8 h-8" />
          </button>
        </main>
      )}
    </DashboardContext.Provider>
  );
};
