import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

const defaultKeywords = [
  'Nomadago',
  'Digital Nomad',
  'Travel',
  'Social Calendar',
  'Travel Network',
  'Travel Planner',
  'Travel App',
  'Travel Community',
  'Travel Friends',
];

interface SEOProps {
  title?: string;
  description?: string;
  author?: string;
  keywords?: string[];
  image?: string;
  noIndex?: boolean;
}

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  keywords = [],
  author,
  image,
  noIndex,
}) => {
  const { socialImage } = useStaticQuery(graphql`
    query {
      socialImage: file(relativePath: { eq: "social-image.jpg" }) {
        childImageSharp {
          resize(width: 1200) {
            src
          }
        }
      }
    }
  `);

  const meta = {
    title: title
      ? `${title} - Nomadago | Travel Planning App for Digital Nomads`
      : 'Nomadago | Travel Planning App for Digital Nomads',
    description:
      description ??
      'Nomadago is a social travel planning app for digital nomads. Plan your travels, stay connected, meet new friends, and discover gobal coliving & coworking spaces!',
    image:
      image ??
      `https://www.nomadago.com${socialImage.childImageSharp.resize.src}`,
  };

  const metaKeywords = useMemo(
    () => [...new Set([...defaultKeywords, ...keywords])],
    [keywords],
  );

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={metaKeywords.join(', ')} />
      <meta name="image" content={meta.image} />
      <meta name="og:title" content={meta.title} />
      <meta name="og:description" content={meta.description} />
      <meta name="og:image" content={meta.image} />
      <meta name="og:url" content="https://www.nomadago.com" />
      <meta name="og:type" content="website" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content="Nomadago" />
      <meta name="twitter:image" content={meta.image} />
      <meta name="twitter:card" content="summary_large_image" />
      {noIndex && <meta name="robots" content="noindex" />}
      {author && <meta name="author" content={author} />}
    </Helmet>
  );
};

export default SEO;
